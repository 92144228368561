import React from "react"
import { graphql } from 'gatsby'

import Layout from '../../../layout'

import BackgroundContainer from '../../../components/image/background-container'
import TitleContainer from '../../../components/common/title-container'
import Title from '../../../components/common/title'
import PageContainer from '../../../components/common/page-container'

import Link from '../../../components/common/link'
import Subtitle from "../../../components/common/subtitle";

export default (props) => (
    <Layout title="Software as a Service - Single sign-on functionality">
        <BackgroundContainer header={props.data.header}>
            <TitleContainer>
                <Title>Software as a Service</Title>
                <Subtitle>Single sign-on functionality</Subtitle>
            </TitleContainer>
        </BackgroundContainer>

        <PageContainer>
            <p>When developing software for enterprise users, single-sign-on makes it easy for customers to roll out a new software-as-a-service project to their users. It also simplifies the on-boarding and off-boarding process for HR teams, ensuring that employees automatically receive access to systems when they join, and lose access to systems when they leave the organisation.</p>
            <h2>SSO with SAML</h2>
            <p>SAML is an open standard for exchanging authentication and authorisation data between systems, typically between an Identity Provider (a system which manages users and authenticates them) and a Service Provider (a seperate system that those users want to use i.e. your software-as-a-service product).</p>
            <h2>Authentication with industry standard systems</h2>
            <p>Single-sign-on makes it possible to let your applications users authenticate with services such as Google Apps or Active Directory, and they can often add your application so that they can quickly login from Google Apps or Active Directory directly.</p>
            <p>Each of your customer accounts can be configured with their own identity provider, so each customers employees only get access to that customers account.</p>
            <h2>Want to integrate SSO into your application?</h2>
            
            <Link href="/contact" className="btn btn-primary">Get in touch</Link>
        </PageContainer>
    </Layout>
)

export const query = graphql`
  query {
    header: file(relativePath: { eq: "saas.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, maxHeight: 1000, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
